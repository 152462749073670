/* not sure i'm doing medias right, recheck that ... */

/* xs (smartphone) styles: */
.nft-details p, .nft-details button {
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 18px;
	margin-bottom: 0.5rem;
}

.site-name {
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 32px;
	font-weight: 600;
	color: #000000;
}

.profile-image {
	width: 100px;
}

.profile-image-frame {
		width: 100px;
    height: 100px;
    overflow: hidden;
    margin: auto;
}

.nft-4up .nft-col {
	padding-top: 5%;
	padding-bottom: 5%;
}

/* everything wider than that: */
@media (min-width: 576px) { 
	.nft-4up .nft-img {
		max-width: 70%;
		max-height: 35vw;
	}
	.nft-4up .nft-col {
		padding-top: 2%;
		padding-bottom: 2%;
	}
	.nft-single {
		max-width: 80rem;
		margin: auto;
	}
	.nft-1up .nft-img {
		max-width: 80vw;
		max-height: 80vh;
	}
	.nft-details {
		text-align: left;
	}
	.nft-metadata {
		text-align: left;
	}
	.nft-buy .row {
		text-align: right;
	}
	.nft-collectors {
		text-align: left;
	}
}
